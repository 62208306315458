import { Link } from "gatsby";
import React from "react";
import { Secondary } from "../../components/layouts/Secondary/Secondary";
import * as styles from "./hotshop-promo.module.sass";

const HotshopPromo = () => (
  <Secondary>
    <main className={styles.main}>
      <h1>Invest in Yourself Promotion </h1>
      <h2>
        <strong>What is this Promotion?</strong>
      </h2>
      <p>
        Hotshop and Flahmingo have partnered up to bring you the Invest in
        Yourself Promotion! Why? Because health is wealth!{" "}
      </p>
      <p>
        Hotshop will be providing all eligible clients with a{" "}
        <strong>free spin class</strong> on March 25, 2023 at 10:30 am at the
        Mission location. This Promotion is{" "}
        <strong>only available to the first 33 clients</strong> (the maximum
        class size) who sign up and complete the required actions to qualify!
      </p>

      <h2>
        <strong>How do I qualify?</strong>
      </h2>
      <p>
        To qualify, you must download the Flahmingo app from the iOS Store or
        Google Play Store and create and fund a Verified Account with a minimum
        of $10 CAD before the class!{" "}
      </p>
      <p>
        {" "}
        This offer is for new Flahmingo clients only who are residents of
        Alberta.
      </p>
      <h2>
        <strong>Promo examples: </strong>
      </h2>
      <ul>
        <li>
          A new client opens and funds a new Flahmingo verified account with $10
          on March 18, 2023. That new client is eligible to receive a free spin
          class from Hotshop on March 25, 2023 at 10:30 am at the Mission
          location.{" "}
        </li>
        <li>
          A new client completes opening their new Flahmingo account and funds
          it on March 26, 2023. That client is not eligible to receive a free
          class from Hotshop on March 25, 2023 at 10:30 am.
        </li>
      </ul>

      <h2>
        <strong>Terms and Conditions:</strong>
      </h2>

      <p>
        By participating in the Health Is Wealth Promotion (“Promotion”), you
        acknowledge that you have read, understood and agreed to the terms and
        conditions outlined in this document. If you have any questions or
        concerns about the Promotion, please contact Flahmingo Investments Inc.
        (“Flahmingo”) at{" "}
        <a href="marketing@flahmingo.com">marketing@flahmingo.com</a> for
        further clarification. </p>
        <p>
        To be eligible for this Promotion, new accounts
        must meet Flahmingo's account opening requirements during the onboarding
        process and be approved by Flahmingo at its discretion (“Verified
        Account'') before the completion of the spin class conducted by HotShop
        Hot Yoga & Spin (“Hotshop”) on March 25, 2023 at 10:30 am at the Mission
        location (2115 4th St SW, Calgary, AB, T2S 1W8). Clients must deposit a
        minimum of $10 CAD and a maximum of $30,000 CAD prior to the class
        starting. </p>
        <p>
        Limit of 1 bonus per client and the bonus is non-transferable.
        This Promotion cannot be combined with other offers or promotions from
        Flahmingo and cannot be applied retroactively. This Promotion is only
        available to residents of Alberta who are of the age of majority in
        Alberta when submitting their application to open a Verified Account.</p>
<p>
        Flahmingo reserves the right to modify, cancel, or limit this Promotion
        at any time, without prior notice. Flahmingo shall be the sole and final
        authority in interpreting and enforcing the rules and regulations of
        this Promotion. In case of suspected fraudulent or abusive practices,
        violation of the terms and conditions, or gaming of the system,
        Flahmingo reserves the right to revoke any and all promotions from your
        Flahmingo account(s) and take such other actions as it deems
        appropriate, including, but not limited to, closing your Flahmingo
        account(s). Participating in this Promotion does not guarantee your
        acceptance as a client of Flahmingo. This Promotion is only valid for
        individuals who reside in Alberta and meet the eligibility requirements.
        The standard terms and conditions regarding your use of Flahmingo and
        any agreements applicable to your Flahmingo account(s) can be found on
        the Flahmingo website.</p>
        <p>
         Flahmingo is not responsible for any errors or omissions in the information provided and will not be liable for any
        loss or damage of any kind resulting from the use of this information.
        Please note that investing in securities carries inherent risks, and you
        should thoroughly research any investment before making a decision. You
        should also consult with a financial advisor to determine if the
        investment is appropriate for you and your individual financial
        situation. 
        </p>
        <p>
        By participating in this Promotion, clients agree to be bound
        by these rules and the decisions of Flahmingo and waive any right to
        claim ambiguity in the Promotion or these terms. Clients also agree to
        hold Flahmingo harmless from any and all damages or claims that may
        arise out of their participation in this Promotion. 
        </p>
        <p>
        In the event of any conflict between these terms and conditions and any other promotional
        material or information related to the Promotion, these terms and
        conditions shall govern. If any provision of these terms and conditions
        is held to be invalid or unenforceable, such provision shall be struck,
        and the remaining provisions shall be enforced.</p>
        <p>
         This Promotion is
        subject to all applicable laws and regulations and is void where
        prohibited by law. All decisions regarding the interpretation of the
        rules, eligibility, and other issues arising under this Promotion shall
        be made by Flahmingo and shall be final.</p>
        <p> This Promotion is governed by
        the laws of the Province of Alberta, Canada and any disputes arising
        from it will be resolved through mediation. The mediator will be chosen
        by Flahmingo. Both parties must bear the cost of their own
        representation until a decision is reached. If the client disagrees with
        the outcome of the decision when escalating the issue to Flahmingo, they
        will be responsible for paying the cost of the mediation and must accept
        the decision as final and binding. No further legal action can be taken
        after the decision has been made. By participating in this program,
        clients agree to these terms.</p>
        <p>
         Securities are offered to clients by
        Flahmingo, a registered restricted dealer in Alberta. This is not an
        offer, distribution, solicitation of an offer or advice to buy or sell
        securities or open a self-directed trading account in any jurisdiction
        where Flahmingo is not registered. Securities offered by Flahmingo are
        not Canadian Investor Protection Fund insured. The broker-dealer used by
        Flahmingo in the United States is a member of the Securities Investor
        Protection Corporation. However, Flahmingo does not represent or warrant
        whether protection may be available to our clients in Canada.</p>
        <p>
        Commission-free trading of securities refers to $0 commissions for
        Flahmingo self-directed individual trades that trade U.S. listed
        securities, including fractional shares. Keep in mind that other fees
        such as foreign exchange fees and paper statement fees may apply to your
        self-directed trading account. Please see our Fee Schedule here:{" "}
        <a href="https://flahmingo.com/fees-schedule/">
          https://flahmingo.com/fees-schedule/
        </a>{" "}
        </p>
        <p>
        All investments involve the risk of loss, and the past performance of a
        security or a financial product does not guarantee future results or
        returns. Clients should be aware that system response, execution price,
        speed, liquidity, market data, and account access times are affected by
        many factors, including market volatility, size and type of order,
        market conditions, system performance, and other factors. These risks
        are to be assumed by the client. Clients should consider the investment
        objectives and unique risk profile of Exchange Traded Funds (ETFs)
        carefully before investing. ETFs are subject to risks similar to those
        of other diversified portfolios. Leveraged and Inverse ETFs may not be
        suitable for all clients and may increase exposure to volatility through
        the use of leverage, short sales of securities, derivatives and other
        complex investment strategies. Third-party information provided for
        Flamingo's marketing communications emanating from its social media
        community, market prices, data and other information available through
        Flahmingo are meant for informational purposes only and are not intended
        as an offer or solicitation for the purchase or sale of any financial
        instrument or as an official confirmation of any transaction. The
        information provided is not warranted as to completeness or accuracy and
        is subject to change without notice. View disclosures here:
        <a href="https://flahmingo.com/legal/privacy-policy/">
          {" "}
          https://flahmingo.com/legal/privacy-policy/
        </a>{" "}
        </p>
        <p>
        This Promotion is offered by Flahmingo Investments Inc. For any
        questions regarding this Promotion, please contact us at
        <a href="marketing@flahmingo.com">marketing@flahmingo.com</a>. </p>
        <p>
        You may
        opt out of the Promotion at any time by notifying us at{" "}
        <a href="marketing@flahmingo.com">marketing@flahmingo.com</a>, in which
        case the cash bonus pursuant to the Promotion will be canceled or
        reversed.
      </p>
      <h2>Affiliate Disclosure:</h2>

      <ul>
        <li>
          This promotion is provided by the following Flahmingo affiliate:
          Hotshop Hot Yoga & Spin and is an agreement between them and
          Flahmingo.
        </li>
        <li>
          Affiliates are eligible for program rewards for every client referred
          who meets certain criteria.
        </li>
        <li>Please refer to the program rewards information for details.</li>
        <li>
          The affiliate has referred you in order to receive compensation from
          Flahmingo.
        </li>
        <li>
          Any activities requiring registration will be done by Flahmingo.
        </li>
        <li>
          Flahmingo Investments Inc. is a registered restricted dealer in the
          province of Alberta. Hotshop Hot Yoga & Spin is not a registered
          dealer or adviser.
        </li>
      </ul>

      <h2>Program Rewards:</h2>

      <p>
        The affiliate will receive $10 cash for every client referred who opens
        a new Flahmingo Verified Account and funds the account with a minimum of
        $10, and keeps the account in good standing for 60 days.
      </p>
    </main>
  </Secondary>
);
export default HotshopPromo;
